import CodeExample from "components/docs/code-examples/CodeExample";
import DocumentationSection from "components/docs/DocumentationSection";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import LINKS from "components/docs/navigation/links";
import ImgFrameSection from "components/docs/sections/ImgFrameSection";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import type { RouterLocation } from "types/types";
import styled from "styled-components";
import NewApiKeys from "../../assets/images/docs/ui/new-api-keys.svg";

interface Props {
  location: RouterLocation;
}
const PseudoPaddedSection = styled.section`
  :target {
    scroll-margin-top: 120px;
  }
`;

const DocumentationAuthenticationPage = ({ location }: Props) => {
  const title = "Authentication";
  const description = "Learn how to authenticate your requests to the Merge API.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <>
          <p className="mt-3">
            When making requests to the Merge API, you will need to pass proper authentication
            parameters so that you can identify yourself as an authorized user.
          </p>
          <p>There are two primary authentication protocols we will explore below:</p>
          <ul>
            <li className="mb-2">
              <a href="#api-key" title="Go to API Keys">
                Merge API Key
              </a>
            </li>

            <li>
              <a href="#account-tokens" title="Go to Linked Account Tokens">
                Linked Account Tokens
              </a>
            </li>
          </ul>
          <p>
            The credentials you retrieve from these protocols need to be included in the headers for
            every request you send to the Merge API.
          </p>
        </>
      </DocumentationSection>
      <hr className="mt-6 mb-9" />
      <PseudoPaddedSection id="api-key">
        <DocumentationSection title="Merge API Key">
          <>
            <p>
              For any request you make when communicating with the Merge API, you will need an API
              key to authenticate yourself as an authorized user. You should have saved your access
              key after creating it in Merge, but if you no longer have it, you can regenerate your
              Production Access Key or create a new Remote Production or Test Access Key in{" "}
              <a href="https://app.merge.dev/keys" target="_blank" rel="noreferrer">
                API Keys
              </a>{" "}
              in your Merge Dashboard.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <NewApiKeys />
            </div>
            <p className="mt-9">
              If you’re writing your own requests, add your API key with a &quot;Bearer &quot;
              prefix as a header called <code>Authorization</code> to authorize your Merge API
              requests. This header must be included in every request in this format:
            </p>
            <p className="mt-6 mb-6">
              <code>Authorization: Bearer YOUR_API_KEY</code>
            </p>
            <p className="mt-9">
              If you’re using the Merge SDK in your backend to communicate with Merge, you will add
              your API key as a parameter during your Merge client initialization.
            </p>
            <CodeExample
              codeBlockName="Merge SDK - Client Initialization"
              folder="basics/authentication/sdk-client-initialization"
              style={{ maxHeight: "none" }}
            />
          </>
        </DocumentationSection>
      </PseudoPaddedSection>
      <hr className="my-9" />
      <PseudoPaddedSection id="account-tokens">
        <DocumentationSection title="Linked Account Tokens">
          <>
            <p>
              When sending requests to the Merge API regarding your end users’ data, you’ll only be
              authorized to access or manipulate that users’ data if they’ve gone through Merge Link
              and you’ve successfully stored their <code>account_token</code> for use with these
              requests.
            </p>
            <p>
              The <code>account_token</code> also serves to signify the particular integration you
              wish to interact with. You can find your <code>account_token</code> at the bottom
              right of each Linked Account&apos;s page under the end user organization information.
            </p>
            <div className="my-9 p-9 d-flex mx-auto justify-content-center">
              <StaticImage
                className="mx-auto"
                src="../../assets/images/ui/link/EndUserPanelWithSpace.svg"
                alt="End user organization information with account_token"
                placeholder="none"
                layout="constrained"
              />
            </div>
            <p>
              Learn how to add Merge Link to your product and store your users’{" "}
              <code>account_tokens</code> <Link to={LINKS.GET_STARTED_LINK.linkTo}>here</Link> and
              see how to use these <code>account_tokens</code> to authenticate your API requests
              below.
            </p>
            <ImgFrameSection>
              <StaticImage
                src="../../assets/images/ui/link/Link@2x.png"
                alt="Preview of Merge Link component"
                placeholder="none"
                layout="constrained"
                width={280}
                style={{
                  boxShadow: "0px 3px 10px rgba(61, 63, 64, 0.3)",
                  borderRadius: 10,
                  overflow: "hidden",
                  transform: "translateZ(0)", // safari needs this to apply border-radius and overflow:hidden correctly
                }}
              />
            </ImgFrameSection>
            <p className="mt-9 mb-0">
              If you’re writing your own requests, add your user’s <code>account_token</code> as a
              header called <code>X-Account-Token</code> to authorize your Merge API requests. The{" "}
              <code>account_token</code> must be included in the headers for every request in this
              format:
            </p>
            <p className="mt-6 mb-6">
              <code>X-Account-Token: END_USER_ACCOUNT_TOKEN</code>
            </p>
            <p className="mt-6 mb-6">
              If you’re using the Merge SDK in your backend to process requests related to your end
              users’ data, you will add your user’s <code>account_token</code> as a parameter called{" "}
              <code>x_account_token</code> to your request.
            </p>
            <CodeExample
              codeBlockName="Merge SDK - Use Account Token"
              folder="basics/authentication/sdk-use-account-token"
              style={{ maxHeight: "none" }}
            />
          </>
        </DocumentationSection>
      </PseudoPaddedSection>
      <CrossGuideLinks location={location} style={{ marginTop: "40px" }} />
    </StaticPageContainer>
  );
};

export default DocumentationAuthenticationPage;
